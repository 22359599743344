import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login', name: 'Login', meta: { requiresAuth: false },
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login'),
  }, { path: '/', redirect: { name: 'Dashboard' } },
  {
    path: '/dashboard', name: 'Dashboard', meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard'),
  }, {
    path: '/distributors', name: 'Distributors', meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "Distributors" */ '../views/Distributors'),
  }, {
    path: '/routes', name: 'Routes', meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "Routes" */ '../views/Routes'),
  }, {
    path: '/customers', name: 'Customers', meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "Customers" */ '../views/Customers'),
  }, {
    path: '/salesOrder', name: 'Sales Order', meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "SalesOrder" */ '../views/SalesOrder'),
  }, {
    path: '/users', name: 'Users', meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "Users" */ '../views/Users'),
  },
  {
    path: '/products', name: 'Products', meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "Users" */ '../views/Products'),
  },
  {
    path: '/complaints', name: 'Complaints', meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "Users" */ '../views/Complaints'),
  },
  {
    path: '/reports', name: 'Reports', meta: { requiresAuth: true },
    component: () => import('../views/Reports'),
  },
  {
    path: '/soReport', name: 'Reports', meta: { requiresAuth: true },
    component: () => import('../components/Reports/SOReport'),
  },
  {
    path: '/distributorReport', name: 'Reports', meta: { requiresAuth: true },
    component: () => import('../components/Reports/DistributorReport'),
  },
]

const router = new VueRouter({ routes })

export default router
router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!localStorage.getItem('token')) {
      router.replace({ path: '/login' }).catch(() => {})
    } else { next() }
  } else {
    if (localStorage.getItem('token')) {
      router.replace({ path: '/dashboard' }).catch(() => {})
    } else { next() }
  }
})
// router.beforeEach((to, from, next) => {
//   let permissionObj = JSON.parse(localStorage.getItem('permissionObj'))
//   let routeArr = []
//   if (permissionObj)
//     permissionObj.forEach(permission => { routeArr.push(permission.name) })
//   console.log(from.name, to.name, routeArr)
//   if (routeArr.length) {
//     if (localStorage.getItem('token')) {
//       if (routeArr.includes(to.name))
//         next()
//     } else {
//       router.replace({ path: '/login' }).catch(() => {})
//     }
//   } else {
//     if (localStorage.getItem('token')) {
//       if (to.name === 'Dashboard' || to.name === 'Login')
//         next()
//     } else {
//       router.replace({ path: '/login' }).catch(() => {})
//     }
//   }
// })
