import moment from "moment";

const Constants = {
  base_url: process.env.BASE_URL,
  api_url: process.env.VUE_APP_API_URL,
  api_base_url: process.env.VUE_APP_API_BASE_URL,
  menuItems: [
    { name: 'Dashboard', to: '/dashboard', icon: 'mdi-view-dashboard' },
    {
      name: 'Distributors', to: '/distributors',
      icon: 'mdi-account-supervisor',
    },
    { name: 'Routes', to: '/routes', icon: 'mdi-map-marker-path' },
    { name: 'Customers', to: '/customers', icon: 'mdi-account-multiple' },
    { name: 'Sales Order', to: '/salesOrder', icon: 'mdi-cart-arrow-right' },
    { name: 'Users', to: '/users', icon: 'mdi-account-group' },
  ],
  DistributorOrderTypes: [
    { id: 1, value: 1, label: 'READY-STOCK' },
    { id: 2, value: 2, label: 'PRE-ORDER' },
  ],
  successToast: {
    theme: 'primary', position: 'top-right',
    duration: 3000, className: 'success-toast',
  },
  errorToast: {
    theme: 'primary', position: 'top-right',
    duration: 3000, className: 'error-toast',
  },
  RTOCodes: [
    { id: 1, code: 'GJ-01', name: 'Ahmedabad' },
    { id: 2, code: 'GJ-02', name: 'Mehsana' },
    { id: 3, code: 'GJ-03', name: 'Rajkot' },
    { id: 4, code: 'GJ-04', name: 'Bhavnagar' },
    { id: 5, code: 'GJ-05', name: 'Surat' },
    { id: 6, code: 'GJ-06', name: 'Vadodra' },
    { id: 7, code: 'GJ-07', name: 'Nadiad' },
    { id: 8, code: 'GJ-08', name: 'Palanpur' },
    { id: 9, code: 'GJ-09', name: 'Himmatnagar' },
    { id: 10, code: 'GJ-10', name: 'Jamnagar' },
    { id: 11, code: 'GJ-11', name: 'Junagadh' },
    { id: 12, code: 'GJ-12', name: 'Bhuj' },
    { id: 13, code: 'GJ-13', name: 'Surendranagar' },
    { id: 14, code: 'GJ-14', name: 'Amreli' },
    { id: 15, code: 'GJ-15', name: 'Valsad' },
    { id: 16, code: 'GJ-16', name: 'Bharuch' },
    { id: 17, code: 'GJ-17', name: 'Godhra' },
    { id: 18, code: 'GJ-18', name: 'Gandhinagar' },
    { id: 19, code: 'GJ-19', name: 'Bardoli' },
    { id: 20, code: 'GJ-20', name: 'Dahod' },
    { id: 21, code: 'GJ-21', name: 'Navsari' },
    { id: 22, code: 'GJ-22', name: 'Rajpipla' },
    { id: 23, code: 'GJ-23', name: 'Anand' },
    { id: 24, code: 'GJ-24', name: 'Patan' },
    { id: 25, code: 'GJ-25', name: 'Porbander' },
    { id: 26, code: 'GJ-26', name: 'Vyara' },
    { id: 27, code: 'GJ-27', name: 'Ahmedabad EAST' },
    { id: 28, code: 'GJ-28', name: 'Surat (Pal)' },
    { id: 29, code: 'GJ-29', name: 'Vadodara (Darjipura)' },
    { id: 30, code: 'GJ-30', name: 'Ahva-Dang' },
    { id: 31, code: 'GJ-31', name: 'Modasa (Arvalli)' },
    { id: 32, code: 'GJ-32', name: 'Veraval (Gir-Somnath)' },
    { id: 33, code: 'GJ-33', name: 'Botad (Botad)' },
    { id: 34, code: 'GJ-34', name: 'Chhota Udepur (C.U)' },
    { id: 35, code: 'GJ-35', name: 'Lunawada (Mahisagar)' },
    { id: 36, code: 'GJ-36', name: 'Morbi (Morbi)' },
    { id: 37, code: 'GJ-37', name: 'Khambhaliya (D.B. Dwarka)' },
    { id: 38, code: 'GJ-38', name: 'Bavla (Ahmedabad)' },

  ],
}
export default Constants
export const dateOptions = {
  'Today': [moment().toDate(), moment().toDate()],
  'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
  'Last Week': [moment().subtract(7, 'days').toDate(), moment().toDate()],
  'This Week': [moment().startOf('week').toDate(), moment().endOf('week').toDate()],
  'Last Month': [
    moment().subtract(1, 'month').startOf('month').toDate(),
    moment().subtract(1, 'month').endOf('month').toDate()],
  'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
}
