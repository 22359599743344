import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
export const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    permissions: [],
  },
  getters: {
    permissions (state) { return state.permissions },
    permissionByScreen (state) {
      return function (name) {
        return state.permissions.filter(permission => permission.name === name)[0]
      }
    },
  },
  mutations: {
    setPermissions (state, payload) { state.permissions = payload },
  },
  actions: {
    setPermissions ({ commit }, payload) { commit('setPermissions', payload) },
  },
})
export default store
