import Vue from 'vue'
import router from './router'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { WebSocketLink } from 'apollo-link-ws'
import { split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import VueApollo from 'vue-apollo'

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP,
})
const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        // token: localStorage.getItem('token'),
        'X-Hasura-Admin-Secret': process.env.VUE_APP_HASURA_ADMIN_SECRET,
      },
    },
  },
})

// Error Handling
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    let data = {
      data: {
        queryObject: operation,
        operationName: operation.operationName,
        headers: operation.getContext().headers,
        errorMessage: '',
      },
    }
    graphQLErrors.map(({ message, locations, path }) => {
        if (message ===
          'Invalid response from authorization hook: Error in $.flag: expected Text, encountered Boolean') {
          localStorage.clear()
          router.replace({ path: '/' })
        } else {
          data.data.errorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          console.log('GraphQL Error Logged')
        }
      },
    )
  }
  if (networkError) {
    if (networkError.message !==
      'start received before the connection is initialised' &&
      networkError.message !==
      'cannot start as connection_init failed with : Invalid response from authorization hook: Error in $.flag: expected Text, encountered Boolean') {
      console.log('GraphQL Network Error Logged')
    }
  }
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // token: localStorage.getItem('token'),
      'X-Hasura-Admin-Secret': process.env.VUE_APP_HASURA_ADMIN_SECRET,
    },
  }
})
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink,
)

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: authLink.concat(errorLink.concat(link)),
  // link: authLink.concat(link),
  cache: new InMemoryCache(),
  connectToDevTools: true,
})

// Install the Vue plugin
Vue.use(VueApollo)

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})
