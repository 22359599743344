import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import * as VueGoogleMaps from 'vue2-google-maps'
import { apolloProvider } from './vue-apollo'
import { VueMaskDirective } from 'v-mask'
import moment from 'moment'
import Constants from './Constants/Constants'
import VueGallery from 'vue-gallery'

Vue.config.productionTip = false
Vue.directive('mask', VueMaskDirective)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC1W5a2sBUgbyjncF5Bik08A70cKv7QVRU',
    region: 'IN', language: 'en',
    libraries: 'places,drawing,visualization',
  },
  installComponents: true,
})
Vue.component('gallery', VueGallery);
Vue.mixin({
  methods: {
    imgWpx(path, size, type){
      let sizes = path.split('/')[2]?.split('-')||[];
      if(!sizes.length || type === 'VIDEO') return  Constants.api_base_url + path;
      if(!sizes.includes(String(size))) size = sizes.pop();
      return  Constants.api_base_url + 'images/uploads/w-'+size+'/' +path.split('/').pop();
    },
    checkVal (val) { return val ? val : '-' },
    dateTimeFilter (val) {
      return val ? moment(val).format('DD MMM YYYY @ hh:mm a') : '-'
    },
    dateFormatter (val) {
      return val ? moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY') : '-'
    },
    orderTypeFilter (orderSystem) {
      let ot = Constants.DistributorOrderTypes.filter(
        val => val.value === orderSystem)
      if (ot.length) return ot[0].label
    },
    currencyFormatter (value) {
      return new Intl.NumberFormat('en-IN', {
        maximumFractionDigits: 2, minimumFractionDigits: 0,
        style: 'currency', currency: 'INR',
      }).format(value)
    },
  },
  filters:{
    dateFormat(val){
      return val ? moment(val).format('DD MMM YYYY') : ''
    },
    currencyFilter (val) {
      return  ![null, undefined].includes(val)?val.toLocaleString('en-IN', { minimumFractionDigits:0, style: 'currency', currency: 'INR' }).replace(/₹/g, '₹'):'₹0'
    },
    currencyFormatter (value) {
      return new Intl.NumberFormat('en-IN', {
        maximumFractionDigits: 2, minimumFractionDigits: 0,
        style: 'currency', currency: 'INR',
      }).format(value)
    },
  }
})

new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
