<template>
  <div>
    <v-dialog @keydown.esc="$emit('close')" max-width="500" persistent v-model="confirmDialog">
      <v-card class="pa-5">
        <v-card-title class="mb-3" style="word-break: break-word" v-html="confirmationMessage"></v-card-title>
        <v-card-actions class="pa-3 pr-5 ">
          <v-spacer></v-spacer>
          <v-btn @click.native="$emit('close')" class="" text>Cancel</v-btn>
          <v-btn @click.once="$emit('confirm')" class="primary" text>Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'ConfirmationDialog',
  props: ['confirmationMessage'],
  data () { return { confirmDialog: true } },
}
</script>
