<template>
  <div id="app">
    <v-app>
      <template v-if="$route.path==='/login' || $route.path==='/'">
        <router-view></router-view>
      </template>
      <template v-else>
        <v-app-bar class="elevation-0 overflow-hidden" color="white" app clipped-left>
          <v-icon @click.stop="onClickMenu" size="34" color="black">mdi-menu</v-icon>
          <div class="ml-3 pt-2" @click="goToDashboard">
            <img alt="" src="../src/assets/logo.png" style="height: 45px;cursor: pointer">
          </div>
          <div class="text-h5 pl-4">{{ $route.name }}</div>
          <v-spacer></v-spacer>
          <div class="mr-6 ml-2">
            <div class="d-flex" style="align-items: center;">
              <v-avatar color="grey" size="36">{{ user.name.charAt(0) }}</v-avatar>
              <div>
                <span class="ml-3 d-block" style="font-size: 14px">{{ user.name }}</span>
                <span class="ml-3 d-block" style="font-size: 12px">{{ user.userType && user.userType.name }}</span>
              </div>
            </div>
          </div>
          <v-tooltip nudge-bottom="50" nudge-right="150">
            <template v-slot:activator="{ on }">
              <v-btn @click.prevent="openDialog" class="error--text" icon v-on="on">
                <v-icon>mdi-power</v-icon>
              </v-btn>
            </template>
            <span>Logout</span>
          </v-tooltip>
        </v-app-bar>
        <div class="d-flex" style="height: 100%">
          <v-navigation-drawer @keydown.esc="drawer=false" width="260" :mini-variant="miniVariant" app clipped dark
                               color="primary" v-model="drawer">
            <v-list class="text-left nav-list">
              <v-list-item exact-active-class="drawer-active font-weight-bold" class="my-2" :key="index"
                           :to="{path: menuItem.to}" v-for="(menuItem,index) in menuItems">
                <v-list-item-icon>
                  <v-icon>{{ menuItem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="menuItem.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <template v-slot:append>
              <div class="pa-2">
                <v-btn icon @click="drawer=!drawer">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </div>
            </template>
          </v-navigation-drawer>
          <v-main>
            <router-view></router-view>
          </v-main>
        </div>
      </template>
    </v-app>
    <ConfirmationDialog :confirmationMessage="confirmMsg" @close="confirmationDialog=false"
                        @confirm="logout" v-if="confirmationDialog"></ConfirmationDialog>
  </div>
</template>

<script>
import ConfirmationDialog from '@/components/Common/ConfirmationDialog'
import Constants from '@/Constants/Constants'
import NetworkCommunicator from '@/plugins/NetworkResourceHandler'
import {GET_PACKAGE_SETTINGS} from "./graphql/queries/Settings";

export default {
  components: { ConfirmationDialog },
  apollo:{
    packageSettings:{
      query: GET_PACKAGE_SETTINGS,
      fetchPolicy: 'network-only',
      skip () {return this.$route.path === '/login'},
      result ({data}) {localStorage.setItem('packageColor', JSON.stringify(data.packageSettings[0].value)) }
    },
  },
  data () {
    return {
      constants: Constants, confirmationDialog: false, confirmMsg: '', toaster: {}, drawer: true, miniVariant: true,
      loading: false, user: localStorage.user ? JSON.parse(localStorage.user) : null,
      menuItems: [],
    }
  },
  mounted () {
    this.menuItems = localStorage.permissionObj ? JSON.parse(localStorage.permissionObj) : []
  },
  methods: {
    onClickMenu () {
      if (!this.drawer) {
        this.drawer = true
        this.miniVariant = true
      } else { this.miniVariant = !this.miniVariant }
    },
    goToDashboard () {
      if (this.$route.name !== 'Dashboard')
        this.$router.push('/dashboard')
    },
    openDialog () {
      this.confirmMsg = 'Are you sure, You want to logout ?'
      this.confirmationDialog = true
    },
    async logout () {
      this.loading = true
      let response = await NetworkCommunicator('GET', `${this.constants.api_url}logout`)
      if (response.flag) {
        localStorage.clear()
        this.confirmationDialog = false
        this.$router.push('/login').catch(() => {})
        this.loading = false
      } else {
        this.toaster = { enable: true, color: 'red', message: response.message }
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss">
body {
  font-family: 'Open Sans', sans-serif;
}

#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.success-toast.toasted.primary {
  background-color: #2c9d28 !important;
  color: #ffffff;
  height: 30px !important;
}

.error-toast.toasted.primary {
  height: 30px !important;
  color: #ffffff;
  background-color: #d61c16 !important;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  display: block !important;
}

::-webkit-scrollbar-thumb {
  background: #c8c8c8 !important;
  border-radius: 5px !important;
}

.v-list {
  padding: 0 !important;
}

.count-widget-all {
  background: #EAF0FD;
  border-radius: 10px;
  min-width: 100px;
  color: #0ba3f5;
  cursor: pointer;
  transition: all 0.3s;
}

.count-widget-all {
  background: #EAF0FD;
  border-radius: 10px;
  min-width: 100px;
  color: #0ba3f5;
  cursor: pointer;
  transition: all 0.3s;
}

.count-widget-all:hover, .count-widget-all-active {
  background: #2563EB;
  color: white;
}

.count-widget {
  background: #FEF6E7;
  border-radius: 10px;
  min-width: 100px;
  color: #F59E0B;
  cursor: pointer;
  transition: all 0.3s;
}

.count-widget:hover, .count-widget-active {
  background: #F59E0B;
  color: white;
}

.count-widget-assign {
  background: #E6F5F0;
  border-radius: 10px;
  min-width: 100px;
  color: #059669;
  cursor: pointer;
  transition: all 0.3s;
}

.count-widget-assign:hover, .count-widget-assign-active {
  background: #059669;
  color: white;
}
/* .PartyPack {
  background-color: #EC2144 !important;
}

.FamilyPack {
  background-color: #DAB44F !important;
}

.CouplePack {
  background-color: #45B1E6 !important;
}
.MRP-10{
  background-color: darkorange !important;
} */
</style>
<style scoped>
/deep/ .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  color: rgba(0, 0, 0, .14);
}

/deep/ .v-btn:not(.v-btn--round).v-size--default {
  height: 40px;
}
</style>
